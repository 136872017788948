// @flow

import { combineReducers } from "redux";
import { List, Map as ImmutableMap } from "immutable";
import * as R from "ramda";
import { createSelector } from "reselect";
import type { List as ListType } from "immutable";
import { EMPTY_OBJECT } from "src/reducers";

import * as atypes from "src/constants/actionTypes";

import type {
  Action,
  ChecklistState,
  FieldId,
  FieldsById as FieldsByIdType,
  FieldsByChecklist as FieldsByChecklistType,
  FieldIds,
  Section,
  ChecklistHeader,
  Checklist,
  ChecklistId,
  ChecklistFieldProperties,
  UniqueInstanceValues,
  FieldsBySections as FieldsBySectionsType,
  RoomId,
  CreatingConversation,
  SelectedChecklist,
  FieldsByForms,
  FormTemplates,
  FormCreationLoader,
  FormValues,
  ExpandedEmbeddedFields,
  SignatureTypes,
  FieldBehaviorByRoom,
  MandatoryFields,
  AppState,
  ChecklistField,
  FormFieldsVisibility,
  WorkflowChecklists,
  FieldValueDetails,
  FormId,
  BehaviorByFormField,
  ValueStatusSlice,
  FieldsById
} from "src/types";
import { behaviorToSettings, behaviors } from "src/conditions";
import { dataStages } from "src/constants";
import { linkedFieldDeleteHandler } from "src/utils/checklist";
import { omitedFields } from "src/constants/processInstanceColumns";

const fieldsById = (
  state: FieldsByIdType = ImmutableMap(),
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SET_CURRENT_CHATROOM_REQUEST:
    case atypes.HIDE_DOCK:
      return ImmutableMap();

    case atypes.GET_CHATROOM_CHECKLIST_FIELDS_SUCCESS:
    case atypes.GET_EMBEDDED_FIELDS_SUCCESS:
      return state.merge(
        R.mergeAll(
          R.map(
            f => ({
              [f.id]: {
                ...f,
                loading: false,
                error: null
              }
            }),
            payload.fields
          )
        )
      );

    case atypes.GET_CHECKLIST_FIELDS_SUCCESS:
      return state.merge(
        R.mergeAll(
          R.map(
            f => ({
              [f.id]: {
                ...f,
                loading: false,
                error: null
              }
            }),
            payload.fields
          )
        )
      );

    case atypes.GET_CHECKLIST_FIELD_VALUES_REQUEST:
      return state.merge(
        R.mergeAll(
          R.map(
            (f: ChecklistFieldProperties) => ({
              [f.id]: {
                ...f,
                loading: false,
                error: null
              }
            }),
            // $FlowFixMe
            Object.values(state.toJS())
          )
        )
      );

    case atypes.GET_PRINCIPAL_CHECKLIST_SUCCESS:
      return state.merge(
        R.mergeAll(
          R.map(
            f => ({ [f.id]: { ...f, loading: false, error: null } }),
            payload.checklist.fields
          )
        )
      );

    // Make sure in the payload the value of`type` attribute
    // is reader.read(field.type)
    case atypes.ADD_EMBEDDED_FIELDS:
    case atypes.UPDATE_CHECKLIST_FIELDS:
      return state.merge(
        R.mergeAll(
          R.map(f => {
            let data = {
              id: f.id,
              ...f.type,
              target: f.target,
              error: null
            };

            if (state.get(String(f.id))) {
              data = {
                // $FlowFixMe
                ...state.get(String(f.id)).toJS(),
                ...data
              };
            }

            return { [f.id]: data };
          }, payload)
        )
      );

    case atypes.FETCH_FORM_SUCCESS:
      return state.merge(
        R.mergeAll(
          payload.fields.map(f => ({
            [f.id]: { ...f, loading: false, error: null }
          }))
        )
      );

    case atypes.GET_CHECKLIST_FIELD_VALUE_CANCELLED: {
      const fieldId = String(payload.fieldId || payload.id);
      const field = state.get(fieldId);

      if (!field) return state;

      const newState = state.set(fieldId, field);
      return newState;
    }

    case atypes.SET_CHECKLIST_VALUE: {
      const fieldId = String(payload.id || payload.fieldId);
      const field = state.get(fieldId);

      if (!field) return state;

      const newState = state.set(fieldId, field);
      return newState;
    }

    case atypes.SET_CHECKLIST_VALUE_SUCCESS:
    case atypes.DELETE_CHECKLIST_VALUE_SUCCESS: {
      const fieldId = String(payload.fieldId || payload.id);
      const field = state.get(fieldId);

      if (!field) return state;

      const newState = state.set(fieldId, field);
      return newState;
    }

    case atypes.SET_CHECKLIST_VALUE_FAILURE: {
      const fieldId = String(payload.fieldId);
      const field = state.get(fieldId);

      if (!field) return state;
      const newState = state.set(fieldId, field);
      return newState;
    }

    case atypes.CLEAR_CHECKLIST_FIELD_ERROR: {
      const fieldId = String(payload.fieldId);
      const field = state.get(fieldId);

      if (!field) return state;

      const newField = field.set("loading", false).set("error", null);
      const newState = state.set(fieldId, newField);
      return newState;
    }

    default:
      return state;
  }
};

const valueStatusInitialState = {
  byRoom: {},
  byForm: {}
};

const valueStatus = (
  state = valueStatusInitialState,
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SET_PROCESS_REQUEST:
    case atypes.SET_CURRENT_CHATROOM_REQUEST:
    case atypes.HIDE_DOCK:
      return valueStatusInitialState;

    case atypes.GET_CHATROOM_CHECKLIST_FIELDS_SUCCESS:
      return R.mergeDeepRight(state, {
        byRoom: {
          [payload.roomId]: R.mergeAll(
            R.map(
              field => ({
                [field.id]: {
                  status: dataStages.idle,
                  error: null
                }
              }),
              payload.fields
            )
          )
        }
      });

    case atypes.GET_CHECKLIST_FIELD_VALUE_CANCELLED:
      return R.mergeDeepRight(state, {
        byRoom: {
          [payload.roomId]: {
            [payload.fieldId]: {
              status: dataStages.idle,
              error: null
            }
          }
        }
      });

    case atypes.GET_CHECKLIST_FIELD_VALUE:
      return R.mergeDeepRight(state, {
        byRoom: {
          [payload.roomId]: {
            [payload.fieldId]: {
              status: dataStages.fetching,
              error: null
            }
          }
        }
      });

    case atypes.GET_CHECKLIST_FIELD_VALUE_SUCCESS:
      return R.mergeDeepRight(state, {
        byRoom: {
          [payload.chatroomId]: {
            [payload.fieldId]: {
              status: dataStages.fetched,
              error: null
            }
          }
        }
      });

    case atypes.GET_CHECKLIST_FIELD_VALUES_SUCCESS:
      return R.mergeDeepRight(state, {
        byRoom: R.reduce(
          (acc, val) => {
            return R.mergeDeepRight(acc, {
              [val[0]]: R.mergeAll(
                // $FlowFixMe
                R.map(
                  f => ({
                    [f.fieldId]: {
                      status: dataStages.fetched,
                      error: null
                    }
                  }),
                  val[1]
                )
              )
            });
          },
          {},
          Object.entries(payload)
        )
      });

    case atypes.GET_CHECKLIST_FIELD_VALUE_FAILURE:
      return R.mergeDeepRight(state, {
        byRoom: {
          [payload.roomId]: {
            [payload.fieldId]: {
              status: dataStages.fetched,
              error: payload.errorMessage
            }
          }
        }
      });

    case atypes.GET_FORM_FIELD_VALUES_SUCCESS:
      return R.mergeDeepRight(state, {
        byForm: R.mapObjIndexed(fields =>
          fields.reduce(
            (prev, fieldId) =>
              R.mergeDeepRight(prev, {
                [fieldId]: {
                  status: dataStages.fetched,
                  error: null
                }
              }),
            {}
          )
        )(payload)
      });

    case atypes.SET_CHECKLIST_VALUE:
      return R.mergeDeepRight(
        state,
        !R.isNil(payload.formId)
          ? {
              byForm: {
                [payload.formId]: {
                  [payload.id]: {
                    status: dataStages.updating,
                    error: null
                  }
                }
              }
            }
          : {
              byRoom: {
                [payload.roomId]: {
                  [payload.id]: {
                    status: dataStages.updating,
                    error: null
                  }
                }
              }
            }
      );

    case atypes.SET_CHECKLIST_VALUE_SUCCESS:
      return R.mergeDeepRight(state, {
        byRoom: {
          [payload.chatroomId]: {
            [payload.fieldId]: {
              status: dataStages.updated,
              error: null
            }
          }
        }
      });

    case atypes.SET_FORM_FIELD_VALUE_SUCCESS:
      return R.mergeDeepRight(state, {
        byForm: {
          [payload.formId]: {
            [payload.fieldId]: {
              status: dataStages.updated,
              error: null
            }
          }
        }
      });

    case atypes.SET_CHECKLIST_VALUE_FAILURE:
      return R.mergeDeepRight(
        state,
        !R.isNil(payload.formId)
          ? {
              byForm: {
                [payload.formId]: {
                  [payload.fieldId]: {
                    status: dataStages.updated,
                    error: payload.errorMessage
                  }
                }
              }
            }
          : {
              byRoom: {
                [payload.roomId]: {
                  [payload.fieldId]: {
                    status: dataStages.updated,
                    error: payload.errorMessage
                  }
                }
              }
            }
      );

    default:
      return state;
  }
};

const fieldsByForms = (
  state: FieldsByForms = {},
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SET_CURRENT_CHATROOM_REQUEST:
    case atypes.HIDE_DOCK:
      return {};
    case atypes.FETCH_FORM_FIELDS_SUCCESS:
    case atypes.LOAD_FORM_FIELDS:
      return { ...state, ...payload.fields };
    case atypes.FETCH_FORM_SUCCESS:
      return {
        ...state,
        [payload.id]: payload.fields.map(field => field.id)
      };
    default:
      return state;
  }
};

const formCreationLoader = (
  state: FormCreationLoader = {},
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SHOW_FORM_CREATION_LOADER:
      return { ...state, [`${payload.roomId}-${payload.fieldId}`]: true };
    case atypes.HIDE_FORM_CREATION_LOADER:
      return { ...state, [`${payload.roomId}-${payload.fieldId}`]: false };
    default:
      return state;
  }
};

const formTemplates = (
  state: FormTemplates = {},
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.FETCH_FORM_TEMPLATES:
      return { ...state, ...payload };
    default:
      return state;
  }
};

const fieldsByChecklist = (
  state: FieldsByChecklistType = ImmutableMap(),
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SET_CURRENT_CHATROOM_REQUEST:
    case atypes.HIDE_DOCK:
      return ImmutableMap();
    case atypes.GET_CHATROOM_CHECKLIST_FIELDS_SUCCESS:
    case atypes.GET_CHECKLIST_FIELDS_SUCCESS:
      if (R.includes(payload.checklistId, state.keySeq().toArray())) {
        return state.mergeIn(
          [`${payload.checklistId}`],
          R.map(R.prop("id"), payload.fields)
        );
      }
      return state.set(
        `${payload.checklistId}`,
        R.map(R.prop("id"), payload.fields)
      );
    default:
      return state;
  }
};

const fieldsBySection = (
  state: FieldsBySectionsType = ImmutableMap(),
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SET_CURRENT_CHATROOM_REQUEST:
    case atypes.HIDE_DOCK:
      return ImmutableMap();
    case atypes.GET_CHECKLIST_FIELDS_BY_SECTIONS:
      if (R.includes(payload.checklistId, state.keySeq().toArray())) {
        return state.mergeIn(
          [`${payload.checklistId}`],
          payload.fieldsBySection
        );
      }
      return state.set(`${payload.checklistId}`, payload.fieldsBySection);
    default:
      return state;
  }
};

const uploadProgress = (state: Object = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.UPDATE_CHECKLIST_FILE_UPLOAD_PROGRESS:
      return {
        ...state,
        [payload.roomId]: {
          ...(state[payload.roomId] || {}),
          [payload.fieldId]: {
            ...state[payload.roomId]?.[payload.fieldId],
            [payload.fileName]: payload.progress
          }
        }
      };
    case atypes.UPLOAD_FILE_TO_CHECKLIST_SUCCESS:
      return {
        ...state,
        [payload.roomId]: R.omit(
          [payload.fileName],
          state[payload.roomId]?.[payload.fieldId] || {}
        )
      };
    default:
      return state;
  }
};

const templates = (
  state: ListType<ChecklistHeader> = List([]),
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.GET_ALL_CHECKLIST_TEMPLATES:
      return List(payload.checklists);
    default:
      return state;
  }
};

const isLoading = (state: boolean = false, { type, payload }: Action) => {
  switch (type) {
    case atypes.GET_CHATROOM_CHECKLIST_FIELDS_FAILURE:
      return false;

    case atypes.GET_CHATROOM_CHECKLIST_FIELDS_REQUEST:
      return true;

    case atypes.GET_CHATROOM_CHECKLIST_FIELDS_COMPLETE:
      return false;

    case atypes.SHOW_DOCK:
      return payload.dockContent === "checklist";

    case atypes.HIDE_DOCK:
      return false;

    case atypes.SET_SELECTED_CHECKLIST_FIELD_FROM_MANAGE_VIEW:
      return !(payload?.fieldId || payload?.fieldId !== null);

    default:
      return state;
  }
};

const showChecklistLoader = (
  state: boolean = false,
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.GET_CHATROOM_CHECKLIST_FIELDS_FAILURE:
      return false;

    case atypes.GET_CHATROOM_CHECKLIST_FIELDS_REQUEST:
      return true;

    case atypes.HIDE_CHECKLIST_LOADER:
      return false;

    case atypes.SHOW_DOCK:
      return payload.dockContent === "checklist";

    case atypes.HIDE_DOCK:
      return false;

    case atypes.SET_SELECTED_CHECKLIST_FIELD_FROM_MANAGE_VIEW:
      return !(payload?.fieldId || payload?.fieldId !== null);

    default:
      return state;
  }
};

const uniqueValues = (
  state: UniqueInstanceValues = {},
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.GET_CHECKLIST_UNIQUE_INSTANCE_VALUES_SUCCESS:
      return payload.uniqueValues;
    case atypes.SET_CURRENT_CHATROOM_REQUEST:
      return {};
    default:
      return state;
  }
};

const searchResult = (state: Array<number> = [], { type, payload }: Action) => {
  switch (type) {
    case atypes.SEARCH_CHECKLIST_SUCCESS:
      return payload.result;
    case atypes.CLEAR_CHECKLIST_SEARCH:
      return [];
    default:
      return state;
  }
};

const creatingConversation = (
  state: CreatingConversation = {},
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.CREATE_CONVERSATION_FROM_CHECKLIST_REQUEST:
      return {
        fieldId: payload.req.fieldId,
        roomId: payload.req.roomId,
        loading: true
      };
    case atypes.CLEAR_CONVERSATION_CHECKLIST_LOADER:
      return {};
    default:
      return state;
  }
};

const selectedChecklist = (
  state: SelectedChecklist = {},
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SET_SELECTED_CHECKLIST_FIELD_FROM_MANAGE_VIEW:
      return payload;
    case atypes.CLOSE_CHECKLIST_MANAGE_VIEW:
      return {};
    default:
      return state;
  }
};

const formValues = (state: FormValues = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.GET_CHECKLIST_FORM_VALUES:
      return { ...state, ...payload };
    case atypes.DELETE_CHECKLIST_VALUE_SUCCESS: {
      const id = `${payload.roomId}-${payload.id}-${payload.formId}`;
      if (!state[id]) return state;

      const newValue = linkedFieldDeleteHandler({ item: state[id], payload });
      return R.mergeDeepRight(state, {
        [id]: newValue
      });
    }
    default:
      return state;
  }
};

const expandedEmbeddedFields = (
  state: ExpandedEmbeddedFields = {},
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.TOGGLE_FIELD_EXPANSION:
      return {
        ...state,
        ...(typeof state[payload.id] === "boolean"
          ? { [payload.id]: !state[payload.id] }
          : { [payload.id]: true })
      };

    case atypes.SET_FIELD_EXPANSION:
      return {
        ...state,
        [payload.id]: payload.value
      };

    case atypes.CREATE_REPORT_SUCCESS:
      const reportId = Object.keys(payload)[0];
      const report = payload[reportId];
      return report.settings?.expansionState?.expandedEmbeddedFields || state;

    case atypes.EDIT_REPORT_SUCCESS:
      return payload.settings?.expansionState?.expandedEmbeddedFields || state;

    case atypes.SET_REPORT_EXPANDED_FIELDS:
      return {
        ...state,
        ...payload
      };

    case atypes.RESET_WORKFLOW_INSTANCE_FILTER:
    case atypes.SET_PROCESS_SUCCESS:
      return {};
    default:
      return state;
  }
};

const expandedPicklists = (state = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.TOGGLE_PICKLIST_EXPANSION:
      return {
        ...state,
        ...(state[payload.instanceId] &&
        state[payload.instanceId][payload.index] &&
        typeof state[payload.instanceId][payload.index].isOpen === "boolean"
          ? {
              [payload.instanceId]: {
                ...state[payload.instanceId],
                [payload.index]: {
                  isOpen: !state[payload.instanceId][payload.index].isOpen,
                  length: payload.length
                }
              }
            }
          : {
              [payload.instanceId]: {
                ...state[payload.instanceId],
                [payload.index]: { isOpen: true, length: payload.length }
              }
            })
      };
    case atypes.RESET_PICKLIST_EXPANSION:
      return {
        ...state,
        [payload.instanceId]: {}
      };
    case atypes.RESET_WORKFLOW_INSTANCE_FILTER:
      return {};
    default:
      return state;
  }
};

const calculateExistingMaxRows = (state, payload) => {
  if (state[payload.instanceId][payload.id]) {
    // if the row is going to be collapsed
    if (state[payload.instanceId].maxRows[0] === payload.maxRows) {
      // if the row that's going to be collapsed has a maxRows
      // equal to the current maxRows, then return remove the current
      // maxRows and the second highest maxRows value becomes the new maxRows
      return [...state[payload.instanceId].maxRows].slice(1, 2);
    } else if (state[payload.instanceId].maxRows[1] === payload.maxRows) {
      // if the row that's going to be collapsed has a maxRows that is
      // equal to the second highest maxRows value then remove that second
      // highest value and current maxRows value stays the same
      return [...state[payload.instanceId].maxRows].slice(0, 1);
    } else {
      return [...state[payload.instanceId].maxRows];
    }
  } else if (state[payload.instanceId].maxRows.length > 0) {
    if (payload.maxRows > state[payload.instanceId].maxRows[0]) {
      // if the incoming maxRows is greater than the existing highest
      // value then make the existing highest, the second highest maxRows
      // value and the incoming maxRows the highest
      return [payload.maxRows, state[payload.instanceId].maxRows[0]];
    } else if (
      payload.maxRows > state[payload.instanceId].maxRows[1] ||
      payload.maxRows === state[payload.instanceId].maxRows[0]
    ) {
      // if the incoming maxRows value is smaller than the highest maxRows
      // value but greater than the second highest value or simply equal to
      // the highest maxRows value then make the incoming value the second
      // highest maxRows value and remove the previous second highest value.
      return [state[payload.instanceId].maxRows[0], payload.maxRows];
    } else {
      // if the incoming value is smaller than both then the maxRows
      // array remains unchanged
      return [...state[payload.instanceId].maxRows];
    }
  }
  // if the array doesn't exist then create one with the incoming
  // maxRows value
  return [payload.maxRows];
};

const calculateNewMaxRows = (state, payload) => {
  if (
    state[payload.instanceId] &&
    state[payload.instanceId].maxRows.length > 0
  ) {
    // if maxRows array exists for that instance
    if (payload.maxRows > state[payload.instanceId].maxRows[0]) {
      // if incoming maxRows value is greater than the highest maxRows value
      return [payload.maxRows, state[payload.instanceId].maxRows[0]];
    } else if (
      (state[payload.instanceId].maxRows[1] &&
        payload.maxRows > state[payload.instanceId].maxRows[1]) ||
      !state[payload.instanceId].maxRows[1]
    ) {
      // if incoming value is greater than second highest maxRows value
      // OR second highest value doesn't exist
      return [state[payload.instanceId].maxRows[0], payload.maxRows];
    } else {
      return [...state[payload.instanceId].maxRows];
    }
  }
  return [payload.maxRows];
};

const expandedEmbeddedRows = (state = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.TOGGLE_ROW_EXPANSION:
      return {
        ...state,
        ...(state[payload.instanceId] &&
        typeof state[payload.instanceId][payload.id] === "boolean"
          ? {
              ...state,
              [payload.instanceId]: {
                ...state[payload.instanceId],
                [payload.id]: !state[payload.instanceId][payload.id],
                maxRows: calculateExistingMaxRows(state, payload)
              }
            }
          : {
              ...state,
              [payload.instanceId]: {
                ...state[payload.instanceId],
                [payload.id]: true,
                maxRows: calculateNewMaxRows(state, payload)
              }
            })
      };
    case atypes.CREATE_REPORT_SUCCESS:
      const reportId = Object.keys(payload)[0];
      const report = payload[reportId];
      return report.settings?.expansionState?.expandedEmbeddedRows || state;
    case atypes.EDIT_REPORT_SUCCESS:
      return payload.settings?.expansionState?.expandedEmbeddedRows || state;
    case atypes.SET_REPORT_EXPANDED_ROWS:
      return {
        ...state,
        ...payload
      };
    case atypes.RESET_WORKFLOW_INSTANCE_FILTER:
    case atypes.SET_PROCESS_SUCCESS:
      return {};
    default:
      return state;
  }
};

const signature = (state: SignatureTypes = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.GET_SIGNATURE_URLS_SUCCESS:
      return payload;
    case atypes.CLEAR_CUSTOM_SIGNATURE:
      return R.omit(["custom"], state);
    default:
      return state;
  }
};

const behaviorByField = (
  state: FieldBehaviorByRoom = {},
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SET_CURRENT_CHATROOM_SUCCESS:
      return R.omit([payload.id], state);
    case atypes.SET_FIELDS_BEHAVIOR:
      return R.mergeDeepRight(state, {
        [payload.roomId]: payload.behaviorByField
      });

    default:
      return state;
  }
};

const mandatoryFields = (
  state: MandatoryFields = [],
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SET_FIELDS_BEHAVIOR:
      return !R.isNil(payload.mandatoryFields)
        ? payload.mandatoryFields
        : state;

    case atypes.SET_CURRENT_CHATROOM_REQUEST:
    case atypes.HIDE_DOCK:
      return [];

    default:
      return state;
  }
};

const visibleFields = (
  state: Array<FieldIds> = [],
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SET_VISIBLE_FIELDS:
      return payload.visibleFields;

    case atypes.SET_CURRENT_CHATROOM_REQUEST:
    case atypes.HIDE_DOCK:
      return [];

    default:
      return state;
  }
};

const behaviorByFormField = (state: Object = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.SET_FORM_FIELDS_BEHAVIOR:
      return payload.behaviorByField;

    default:
      return state;
  }
};

const mandatoryFormFields = (
  state: MandatoryFields = [],
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SET_FORM_FIELDS_BEHAVIOR:
      return payload.mandatoryFields;

    case atypes.SET_CURRENT_CHATROOM_REQUEST:
    case atypes.HIDE_DOCK:
      return [];

    default:
      return state;
  }
};

const currentChecklistId = (
  state: ?number = null,
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.GET_CHECKLIST_FIELDS_BY_SECTIONS:
      return payload.checklistId;
    default:
      return state;
  }
};

const sections = (state = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.OPEN_SECTION:
      return R.mergeDeepRight(state, {
        [payload.id]: true
      });

    case atypes.CLOSE_SECTION:
      return R.mergeDeepRight(state, {
        [payload.id]: false
      });

    case atypes.GET_CHECKLIST_FIELD_VALUES_REQUEST:
      return {};

    default:
      return state;
  }
};

const subSections = (state = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.OPEN_SUB_SECTION:
      return R.mergeDeepRight(state, {
        [payload.id]: true
      });

    case atypes.CLOSE_SUB_SECTION:
      return R.mergeDeepRight(state, {
        [payload.id]: false
      });

    case atypes.GET_CHECKLIST_FIELD_VALUES_REQUEST:
      return {};

    default:
      return state;
  }
};

export const getChecklistFormValue = (state: ChecklistState, id: string) =>
  state.formValues[id];

const fields = combineReducers({
  byId: fieldsById,
  valueStatus,
  byChecklist: fieldsByChecklist,
  bySections: fieldsBySection,
  byForms: fieldsByForms,
  behaviorByField,
  mandatoryFormFields,
  mandatoryFields,
  visibleFields,
  formTemplates,
  uploadProgress,
  formCreationLoader,
  behaviorByFormField
});

const checklist = combineReducers<Object, Action>({
  sections,
  subSections,
  fields,
  formValues,
  templates,
  isLoading,
  showChecklistLoader,
  uniqueValues,
  searchResult,
  creatingConversation,
  selectedChecklist,
  expandedEmbeddedFields,
  expandedPicklists,
  expandedEmbeddedRows,
  signature,
  currentChecklistId
});

export default checklist;

export const getChecklistTemplates = (
  state: ChecklistState
): Array<Checklist> => (state.templates ? state.templates.toArray() : []);

export const getChecklistFields = (
  state: ChecklistState,
  id: string
): FieldIds => state.fields.byChecklist.get(id) || List([]);

export const getChecklistFieldsByForm = (state: ChecklistState) =>
  state.fields.byForms || {};

export const getFormChecklistFields = (state: ChecklistState, id: string) =>
  state.fields.byForms[id] || [];

export const getChecklistFieldDetails = (
  state: ChecklistState,
  id: string
): ?ChecklistField => {
  if (state) {
    return state.fields.byId.get(id);
  }
};

const getFieldsById = (state: ChecklistState) => state.fields.byId;

export const getChecklistFieldsById = createSelector(
  [getFieldsById],
  fieldsById => fieldsById
);

export const getFieldsByChecklist = (state: ChecklistState) =>
  state.fields.byChecklist;

export const getFieldsByChecklistId = (state: ChecklistState, id: string) =>
  // $FlowFixMe - optional chaining not yet supported
  state.fields.byChecklist.toJS()?.[id] || [];

export const getFieldsBySections = (state: ChecklistState) =>
  state.fields.bySections;

export const getChecklistFileUploadProgress = (
  state: ChecklistState,
  roomId: RoomId,
  fieldId: number
) =>
  state.fields.uploadProgress[roomId]
    ? state.fields.uploadProgress[roomId][`${fieldId}`]
    : null;

export const getChecklistFieldLabel = (fieldId: ?number) =>
  createSelector(getChecklistFieldsById, (checklistById: FieldsByIdType) => {
    // $FlowFixMe
    return checklistById?.get(`${fieldId}`)?.get("label") || "";
  });

export const getChecklistFieldType = (fieldId: ?number) =>
  createSelector(getChecklistFieldsById, (checklistById: FieldsByIdType) => {
    // $FlowFixMe
    return checklistById?.get(`${fieldId}`)?.get("type") || "";
  });

export const getChecklistFieldSettings = (fieldId: ?number) =>
  createSelector(getChecklistFieldsById, (checklistById: FieldsByIdType) => {
    // $FlowFixMe
    return checklistById?.get(`${fieldId}`)?.get("settings") || "{}";
  });

export const getChecklistFieldSourceSettings = (fieldId: ?number) =>
  createSelector(getChecklistFieldsById, (checklistById: FieldsByIdType) => {
    return (
      // $FlowFixMe
      checklistById?.get(`${fieldId}`)?.get("sourceProcessSettings") || "{}"
    );
  });

export const getChecklistFieldLinkedSettings = (fieldId: ?number) =>
  createSelector(getChecklistFieldsById, (checklistById: FieldsByIdType) => {
    return (
      // $FlowFixMe
      checklistById?.get(`${fieldId}`)?.get("linkedProcessSettings") || "{}"
    );
  });

export const getChecklistFieldTypes = (filteredColumn: Array<Object>) =>
  createSelector(getChecklistFieldsById, (checklistById: FieldsByIdType) => {
    const fieldTypes = {};
    for (const column of filteredColumn) {
      if (column.label) {
        const settings = (() => {
          try {
            return JSON.parse(column.settings || {});
          } catch (error) {
            return {};
          }
        })();

        // $FlowFixMe
        fieldTypes[column.label] = settings?.fields?.map(
          // $FlowFixMe
          fieldId => checklistById?.get(`${fieldId}`)?.get("type") || ""
        );
      }
    }

    return fieldTypes;
  });

export const getSignatureByType = (
  state: ChecklistState,
  signatureType: $Keys<SignatureTypes>
) => state.signature[signatureType];

export const getFieldsBehavior = (state: ChecklistState) =>
  state.fields.behaviorByField || EMPTY_OBJECT;

export const getChecklistFieldBehavior = createSelector(
  [
    getFieldsBehavior,
    (state, roomId) => roomId,
    (state, roomId, fieldId) => fieldId
  ],
  (behavior, roomId, fieldId) => {
    return behavior?.[roomId]?.[`${fieldId}`]?.behavior || EMPTY_OBJECT;
  }
);

const getFieldBehaviorByFormField = (
  state: ChecklistState
): BehaviorByFormField => state.fields.behaviorByFormField;

export const getBehaviorByFormField = createSelector(
  [getFieldBehaviorByFormField, (state: ChecklistState, id: string) => id],
  (behaviorByFormField, id) => {
    return behaviorByFormField?.[id]?.behavior || {};
  }
);

export const getFormFieldLocked = (state: ChecklistState, id: string) => {
  return (
    state.fields.behaviorByFormField?.[id]?.behavior?.current ===
    behaviorToSettings[behaviors.disableField]
  );
};

export const getFormFieldHidden = (state: ChecklistState, id: string) => {
  return (
    state.fields.behaviorByFormField?.[id]?.behavior?.current ===
    behaviorToSettings[behaviors.hideField]
  );
};

export const getFormFieldMandatoryStatus = (
  state: ChecklistState,
  id: string
) => state.fields.mandatoryFormFields.includes(id);

export const getRevisionField = (
  state: ChecklistState,
  checklistId: ChecklistId
) => {
  const fieldId = (state.fields.byChecklist.get(`${checklistId}`) || []).find(
    fieldId => {
      const field = state.fields.byId.get(`${fieldId}`);

      return field ? field.get("type") === "revision" : false;
    }
  );
  return !fieldId ? null : state.fields.byId.get(`${fieldId}`);
};

export const getMandatoryFieldIds = (state: ChecklistState) => [
  ...state.fields.mandatoryFields,
  ...state.fields.mandatoryFormFields
];

export const getMandatoryFields = (state: ChecklistState) =>
  state.fields.mandatoryFields;

export const getMandatoryFormFields = (state: ChecklistState) =>
  state.fields.mandatoryFormFields;

export const getVisibleFields = (state: ChecklistState) =>
  state.fields.visibleFields;

export const getMandatoryFieldCount = createSelector(
  [getMandatoryFields, getMandatoryFormFields, getVisibleFields],
  (mandatoryFields, mandatoryFormFields, visibleFields) => {
    const visibleMandatoryFields = R.intersection(
      visibleFields,
      mandatoryFields
    );
    return R.uniq([...visibleMandatoryFields, ...mandatoryFormFields]).length;
  }
);

export const getWhetherMandatoryField = (
  state: ChecklistState,
  fieldId: FieldId
) => state.fields.mandatoryFields.includes(fieldId);

export const getSectionMandatoryFieldCount = (
  state: ChecklistState,
  workflowChecklist: WorkflowChecklists,
  params: {
    sectionId: FieldId,
    fields: Array<FieldId | Object>,
    checklistId: ChecklistId,
    roomId: RoomId
  }
) => {
  const { sectionId, fields, checklistId, roomId } = params;
  // $FlowFixMe
  const fieldsBySection: Array<FieldId | Section> = state.fields.bySections.get(
    `${checklistId}`
  );

  let allFields = fields;
  let allMandatoryFormFields = [];
  let allFormFields = [];

  // Extract mandatory fields from given forms
  const extractMandatoryFormFields = (forms: Array<number>) => {
    const checklistValues: Array<FieldValueDetails> =
      workflowChecklist.values[roomId];
    const fieldsByForm: FieldsByForms = state.fields.byForms;
    const mandatoryFormFields: MandatoryFields =
      state.fields.mandatoryFormFields;

    const formsList = checklistValues.filter(item =>
      forms.includes(item.fieldId)
    );

    const formFields: Array<FormId> = formsList.reduce(
      (accumulator, current) => {
        return accumulator.concat(current.val.value);
      },
      []
    );

    formFields.forEach(key => {
      if (fieldsByForm[key]) {
        fieldsByForm[key].forEach(value => {
          allFormFields.push(`${roomId}-${value}-${key}`);
        });
      }
    });

    return R.intersection(allFormFields, mandatoryFormFields) || [];
  };

  const currentSection: ?Section = (fieldsBySection ?? []).find(
    item => typeof item === "object" && item.sectionId === sectionId
  );

  const sectionForms: Array<FieldId> = R.intersection(
    fields,
    R.values(state.fields.byId.toJS())
      .filter(field => field.type === "form")
      .map(field => field.id) || []
  );

  // If section has forms, get its mandatory fields
  if (sectionForms.length > 0) {
    allMandatoryFormFields = [...extractMandatoryFormFields(sectionForms)];
  }

  const allSubSections: Array<FieldId | Section> =
    R.values(state.fields.byId.toJS())
      .filter(field => field.type === "subSection")
      .map(field => field.id) || [];

  if (allSubSections.length > 0 && currentSection?.sectionId) {
    // Find sub sections under current section
    const subSections: Array<FieldId | Section> = allSubSections.map(id => {
      // $FlowFixMe
      return currentSection?.fields.find(
        item => typeof item === "object" && item.sectionId === id
      );
    });

    const subSectionFields = subSections.reduce((accumulator, currentValue) => {
      if (currentValue && typeof currentValue === "object") {
        return accumulator.concat(currentValue.fields);
      }
      return accumulator;
    }, []);

    const subSectionForms: Array<FieldId> = R.intersection(
      subSectionFields,
      R.values(state.fields.byId.toJS())
        .filter(field => field.type === "form")
        .map(field => field.id) || []
    );

    if (subSectionFields.length > 0) {
      allFields = [...allFields, ...subSectionFields];
    }

    // If sub section has forms, find its mandatory fields
    if (subSectionForms.length > 0) {
      allMandatoryFormFields = [...extractMandatoryFormFields(subSectionForms)];
    }
  }
  const visibleMandatoryFields = R.intersection(
    state.fields.visibleFields,
    state.fields.mandatoryFields
  );

  return (
    R.intersection(
      visibleMandatoryFields,
      R.flatten(
        allFields.map((field: Object | FieldId) =>
          typeof field === "object" ? field.fields : field
        )
      )
    ).length + allMandatoryFormFields.length
  );
};

const getProvidedRoomId = (
  state: ChecklistState,
  formId: string,
  roomId: RoomId
) => roomId;
const getProvidedFormId = (state: ChecklistState, formId: string) => formId;

export const getFormMandatoryFieldCount = createSelector(
  [
    state => state,
    getProvidedFormId,
    getFormChecklistFields,
    getProvidedRoomId
  ],
  (state, formId, formFields, roomId) => {
    // Flatten out by taking fields in sections out of it
    const flattenedFormFields: Array<FieldId> = R.flatten(
      formFields.map((field: Object | FieldId) =>
        typeof field === "object" ? field.fields : field
      )
    );
    const formInstanceFieldIds = flattenedFormFields.map(
      field => `${roomId}-${field}-${formId}`
    );

    return R.intersection(
      state.fields.mandatoryFormFields,
      formInstanceFieldIds
    ).length;
  }
);

export const getAllFieldsBySections = (
  state: ChecklistState,
  checklistId: ChecklistId
) => {
  const fieldsBySection = state.fields.bySections.get(`${checklistId}`);

  const allFieldsBySections: {
    topLevel: number[],
    sections: {
      [string]: {
        topLevel: number[],
        subSections: { [string]: number[] }
      }
    }
  } = { topLevel: [], sections: {} };

  // $FlowFixMe
  const indexOfFirstSection = fieldsBySection.findIndex(
    item => typeof item === "object"
  );

  if (indexOfFirstSection !== -1) {
    // $FlowFixMe
    allFieldsBySections.topLevel = fieldsBySection.slice(
      0,
      indexOfFirstSection
    );
  } else {
    // $FlowFixMe
    allFieldsBySections.topLevel = fieldsBySection;
  }

  const sections: Array<{
    sectionId: number,
    fields: Array<number | Section>
    // $FlowFixMe
  }> = fieldsBySection.filter(item => typeof item === "object");
  let sectionIds = [];
  let subsectionIds = [];

  sections.forEach(section => {
    const sectionId = `${section.sectionId}`;
    sectionIds.push(section.sectionId);
    allFieldsBySections.sections[sectionId] = {
      topLevel: [],
      subSections: {}
    };

    section.fields.forEach(item => {
      if (typeof item === "number")
        allFieldsBySections.sections[sectionId].topLevel.push(item);
      else {
        subsectionIds.push(item.sectionId);
        allFieldsBySections.sections[sectionId].subSections[
          `${item.sectionId}`
        ] = item.fields;
      }
    });
  });

  return { allFieldsBySections, sectionIds, subsectionIds };
};

export const getDependentFieldsOfFields = (
  state: ChecklistState,
  fields: number[]
): number[] => {
  return R.uniq(
    fields.reduce((prevFields, fieldId) => {
      try {
        // $FlowFixMe
        const field = state.fields.byId.get(`${fieldId}`).toJS();

        const settings = JSON.parse(field.settings || "{}");

        if (!settings) return prevFields;

        let dependentFields = [];

        if (settings?.conditionBlocks) {
          dependentFields = settings.conditionBlocks.reduce(
            (prevDepFields, cBlock) => {
              return [
                ...prevDepFields,
                ...cBlock.conditions.map(item => item.checklistFieldId)
              ];
            },
            []
          );
        }

        // Add user fields to dependent fields,
        // if they are configured under approval.
        if (field.type === "approval") {
          const userFields = [
            ...(settings.approvers.fields ?? []),
            ...(settings.cancellers.fields ?? [])
          ];

          if (userFields.length > 0) {
            dependentFields = [...prevFields, ...userFields];
          }
        }

        dependentFields = R.reject(R.isNil, R.uniq(dependentFields));

        return [...prevFields, ...dependentFields];
      } catch (error) {
        console.error(error);
        return prevFields;
      }
    }, [])
  );
};

export const getApprovalFieldIds = (state: ChecklistState): FieldId[] => {
  return state.fields.byId
    .valueSeq()
    .filter(field => field.get("type") === "approval")
    .map(field => field.get("id"))
    .toArray();
};

const canFieldBeMandatory = (state: ChecklistState, fieldId: FieldId) => {
  try {
    const field = state.fields.byId.get(`${fieldId}`);
    const settings = JSON.parse(field.get("settings") || "{}");

    if (!R.path(["conditionBlocks"], settings)) return false;

    const { conditionBlocks } = settings;

    if (R.path(["defaultState"], settings) === "mandatory") return true;

    return R.any(R.path(["behavior", "mandatory"]))(conditionBlocks);
  } catch (e) {
    console.error(e);
    return false;
  }
};

const getPosssibleMandatoryFields = (
  state: ChecklistState,
  checklistId: ChecklistId
) =>
  R.filter(
    fieldId => canFieldBeMandatory(state, fieldId),
    state.fields.byChecklist.get(`${checklistId}`)
  );

export const getNecessaryFields = (
  state: ChecklistState,
  checklistId: ChecklistId
): number[] => {
  const { sections: sectionsState, subSections: subSectionsState } = state;

  const { allFieldsBySections, sectionIds, subsectionIds } =
    getAllFieldsBySections(state, checklistId);

  const openSections = Object.keys(allFieldsBySections.sections).filter(
    section => sectionsState[section]
  );

  const fieldsOfOpenSections = openSections.reduce((prev, curr) => {
    const subSectionFields = [];

    Object.keys(allFieldsBySections.sections[curr].subSections).forEach(
      subSection => {
        if (subSectionsState[subSection]) {
          subSectionFields.push(
            ...allFieldsBySections.sections[curr].subSections[`${subSection}`]
          );
        }
      }
    );

    return [
      ...prev,
      ...allFieldsBySections.sections[curr].topLevel,
      ...subSectionFields
    ];
  }, []);

  const possibleMandatoryFields = getPosssibleMandatoryFields(
    state,
    checklistId
  );

  let openFields: FieldId[] = [
    ...sectionIds,
    ...subsectionIds,
    ...allFieldsBySections.topLevel,
    ...fieldsOfOpenSections,
    ...possibleMandatoryFields
  ];

  const approvalFieldIds = getApprovalFieldIds(state);

  if (!R.isEmpty(approvalFieldIds)) {
    openFields = openFields.concat(approvalFieldIds);
  }

  const depFields = getDependentFieldsOfFields(state, openFields);

  const necessaryFields = [...openFields, ...depFields];

  return R.uniq(necessaryFields);
};

export const getRoomFieldsValueStatus = (
  state: ChecklistState,
  roomId: RoomId
): Array<{
  fieldId: FieldId,
  roomId: RoomId,
  status: number, // TODO:
  error: string | null
}> =>
  Object.entries(state.fields.valueStatus.byRoom[roomId] || {}).map(
    ([fieldId, statusData]) => ({
      roomId,
      fieldId,
      ...statusData
    })
  );

export const getValueStatusByRoom = (state: ChecklistState) =>
  state.fields.valueStatus.byRoom;

export const getRoomFieldValueStatus = createSelector(
  [
    getValueStatusByRoom,
    (state, fieldId) => fieldId,
    (state, fieldId, roomId) => roomId
  ],
  (
    valueStatusByRoom: $PropertyType<ValueStatusSlice, "byRoom">,
    fieldId: FieldId,
    roomId: RoomId
  ) => R.path([roomId, fieldId, "status"], valueStatusByRoom)
);

export const getRoomFieldValueError = (
  state: ChecklistState,
  fieldId: FieldId,
  roomId: RoomId
) => R.path([roomId, fieldId, "error"], state.fields.valueStatus.byRoom);

const getValueStatusByForm = (state: ChecklistState) =>
  state.fields.valueStatus.byForm;

export const getFormFieldValueStatus = createSelector(
  [
    getValueStatusByForm,
    (state, fieldId) => fieldId,
    (state, fieldId, formId) => formId
  ],
  (valueStatusByForm, fieldId: FieldId, formId: ?number) => {
    return formId
      ? R.path([formId, fieldId, "status"], valueStatusByForm)
      : undefined;
  }
);

const filterIdleFields = (
  state: ChecklistState,
  fields: FieldId[],
  roomId: RoomId
) =>
  R.filter(
    fieldId =>
      getRoomFieldValueStatus(state, fieldId, roomId) === dataStages.idle,
    fields
  );

export const getFieldsNeededToShowChecklist = (
  state: ChecklistState,
  checklistId: ChecklistId
): FieldId[] => {
  const { allFieldsBySections, sectionIds, subsectionIds } =
    getAllFieldsBySections(state, checklistId);

  const possibleMandatoryFields = getPosssibleMandatoryFields(
    state,
    checklistId
  );

  let toFetch = [
    ...allFieldsBySections.topLevel,
    ...possibleMandatoryFields,
    ...sectionIds,
    ...subsectionIds
  ];

  const approvalFieldIds = getApprovalFieldIds(state);

  if (!R.isEmpty(approvalFieldIds)) {
    toFetch = toFetch.concat(approvalFieldIds);
  }

  toFetch = R.uniq(toFetch);

  const depFields = getDependentFieldsOfFields(state, toFetch);

  return R.uniq([...toFetch, ...depFields]);
};

export const getTopLevelFieldsToFetch = (
  state: ChecklistState,
  checklistId: ChecklistId,
  roomId: RoomId
): FieldId[] => {
  const toFetch = getFieldsNeededToShowChecklist(state, checklistId);

  return filterIdleFields(state, toFetch, roomId);
};

const getFieldsToFetch = (
  state: ChecklistState,
  fields: number[],
  roomId: RoomId
) => {
  const depFields = getDependentFieldsOfFields(state, fields);

  const toFetch = R.uniq([...fields, ...depFields]);

  return filterIdleFields(state, toFetch, roomId);
};

export const getSectionFieldsToFetch = (
  state: AppState,
  checklistId: ChecklistId,
  sectionId: string,
  roomId: RoomId
): number[] => {
  const { allFieldsBySections } = getAllFieldsBySections(
    state.checklist,
    checklistId
  );

  const fieldsInsideThisSection =
    allFieldsBySections.sections[sectionId].topLevel;

  return getFieldsToFetch(state.checklist, fieldsInsideThisSection, roomId);
};

export const getSubSectionFieldsToFetch = (
  state: AppState,
  checklistId: ChecklistId,
  sectionId: string,
  subSectionId: string,
  roomId: RoomId
): number[] => {
  const { allFieldsBySections } = getAllFieldsBySections(
    state.checklist,
    checklistId
  );

  const fieldsInsideThisSection =
    allFieldsBySections.sections[sectionId].subSections[subSectionId];

  return getFieldsToFetch(state.checklist, fieldsInsideThisSection, roomId);
};

export const getFormFields = (state: ChecklistState, formId: number) => {
  const fields = state.fields.byForms[`${formId}`];
  if (!fields) {
    return [];
  }
  return fields;
};

const getFieldsByForm = (state: ChecklistState) => state.fields.byForms;

const getFormFieldsVisibility = (
  state: ChecklistState,
  formFieldsVisibility: FormFieldsVisibility
) => formFieldsVisibility;

/**
 * Returns all the form fields as columns for the table for forms that
 * are expanded
 */
export const getEmbeddedFormColumns = createSelector(
  [getFieldsByForm, getFieldsById, getFormFieldsVisibility],
  (fieldsByForm, fieldsById, formFieldsVisibility) => {
    let embeddedColumnsByForm = {};

    const formFieldIds = R.keys(formFieldsVisibility);

    formFieldIds.forEach(formFieldId => {
      const expandedForms = R.keys(formFieldsVisibility[formFieldId]);

      expandedForms.forEach((form, formIndex) => {
        const formFields = fieldsByForm[form] || [];
        if (!formFieldsVisibility[formFieldId][form]) return;
        formFields.forEach((fieldId, fieldIndex) => {
          const field = fieldsById.get(`${fieldId}`);
          if (!field || omitedFields.includes(field.get("type"))) return;
          embeddedColumnsByForm[formFieldId] = [
            ...(embeddedColumnsByForm[formFieldId] || []),
            {
              header: field.get("label"),
              label: field.get("label"),
              id: `${formFieldId}-${fieldId}`,
              fieldId: `${fieldId}`,
              type: field.get("type"),
              settings: field.get("settings"),
              formIndex,
              formId: form,
              formField: true,
              showTitle: fieldIndex === 0
            }
          ];
        });
      });
    });

    return embeddedColumnsByForm;
  }
);

const getCurrentWorkflowId = (
  state: ChecklistState,
  currentWorkflowId: ?string
) => currentWorkflowId;

export const getExpandedFields = (state: ChecklistState) =>
  state.expandedEmbeddedFields;

/**
 * Returns all the embedded fields as columns for the table for
 * conversation fields that are expanded
 */
export const getEmbeddedConversationColumns = createSelector(
  [getFieldsById, getExpandedFields, getCurrentWorkflowId],
  (fieldsById, expandedEmbeddedFields, currentWorkflowId) => {
    let embeddedColumnsByField = {};

    const conversationFieldIds = R.keys(expandedEmbeddedFields);

    conversationFieldIds.forEach(column => {
      const conversationField = fieldsById.get(
        `${R.last((column || "").split("-")) || ""}`
      );
      // $FlowFixMe
      const settings = JSON.parse(conversationField?.get("settings") || "{}");
      const embeddedFields = settings.fields || [];

      if (!expandedEmbeddedFields[column]) return;

      embeddedFields.forEach(embeddedField => {
        // $FlowFixMe
        const field = fieldsById.get(`${embeddedField}`)?.toJS() || null;
        if (!field || omitedFields.includes(field.type)) return;
        const type = field.type;
        let label = field.label;
        let settings = field.settings;

        if (type === "link") {
          if (
            parseInt(currentWorkflowId) === field.linkedProcessSettings.workflow
          ) {
            label = field.linkedProcessSettings.label;
            settings = JSON.stringify(field.linkedProcessSettings);
          }
        }

        embeddedColumnsByField[column] = [
          ...(embeddedColumnsByField[column] || []),
          {
            header: label,
            label,
            id: `${column}-${embeddedField}`,
            fieldId: `${embeddedField}`,
            type,
            settings,
            embeddedField: true
          }
        ];
      });
    });

    return embeddedColumnsByField;
  }
);

export const getExpandedRows = (state: ChecklistState) =>
  state.expandedEmbeddedRows;

export const getExpandedPicklists = (state: ChecklistState) =>
  state.expandedPicklists;

const getFieldId = (state: ChecklistState, id: string) => id;

export const getIsRowExpanded = createSelector(
  [getExpandedRows, getFieldId],
  (expandedRows, id) => expandedRows[id]
);

export const getSelectedForms = createSelector(
  getChecklistFieldDetails,
  formField => JSON.parse(formField.get("settings")).selectedForms
);

export const selectSelectedChecklist = (state: ChecklistState) =>
  state.selectedChecklist;

export const getSelectedChecklist = createSelector(
  [selectSelectedChecklist],
  selectedChecklist => selectedChecklist
);

export const getSelectedChecklistFieldId = (state: ChecklistState) =>
  state.selectedChecklist.fieldId;

export const getSelectedChecklistValue = (state: ChecklistState) =>
  state.selectedChecklist.value;

export const getChecklistLoaderState = (state: ChecklistState) =>
  state.showChecklistLoader;

export const getExpansionState = createSelector(
  [getExpandedFields, getExpandedRows],
  (expandedFields, expandedRows) => ({
    expandedEmbeddedRows: expandedRows,
    expandedEmbeddedFields: expandedFields
  })
);
export const getCreatingConversation = (state: ChecklistState) =>
  state.creatingConversation;

export const getCurrentChecklistId = (state: ChecklistState) =>
  state.currentChecklistId;

export const getIsFieldFetched = (state: ChecklistState, fieldId: FieldId) => {
  return R.has(`${fieldId}`, state.fields.byId);
};

export const getAllFormTemplates = (state: ChecklistState) =>
  state.fields.formTemplates || {};

export const getIsChecklistLoading = (state: ChecklistState) => state.isLoading;

export const getRoomChecklistFields = createSelector(
  [getFieldsBySections, (state, id: ChecklistId) => id],
  (fieldsBySections, checklistId: ChecklistId) => {
    let sectionFields = [];
    const fieldsArray = fieldsBySections.get(`${checklistId}`);

    (fieldsArray || []).forEach((n: number) => {
      if (typeof n === "object") {
        sectionFields = [...sectionFields, ...n.fields];
      }
    });

    const fields = (fieldsArray || []).filter(
      item => !sectionFields.includes(item)
    );

    return fields;
  }
);

const getFormCreationLoader = (state: ChecklistState) =>
  state.fields.formCreationLoader;

export const getIsFormCreating = createSelector(
  [getFormCreationLoader, (state: ChecklistState, id: string) => id],
  (formCreationLoader, id) => formCreationLoader[id]
);

export const getNavigateUserToNewRevision = createSelector(
  [getChecklistFieldsById, (state, fieldId) => fieldId],
  (fieldsById: FieldsById, fieldId: FieldId) => {
    // $FlowFixMe - Flow doesn't support optional chaining
    const fieldSettings = fieldsById.get(`${fieldId}`)?.get("settings") || "{}";
    const parsedSettings = JSON.parse(fieldSettings);
    // $FlowFixMe - Flow doesn't support optional chaining
    const fieldType = fieldsById.get(`${fieldId}`)?.get("type");

    // For older revision fields, revision creation should behave as it
    // used to before - should not navigate to the newly created 
    // conversation, unless navigateToNewRevision option is enabled 
    // explicitly
    if (
      fieldType === "revision" &&
      (R.isEmpty(parsedSettings) ||
        !parsedSettings ||
        !R.has("navigateToNewRevision", parsedSettings))
    ) {
      return false;
    }

    return parsedSettings?.navigateToNewRevision;
  }
);
